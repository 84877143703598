<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$fun.routerToPage('/user/index')" />
    </template>
    <template #default>
      <div class="search">
        <inputBoxVue
          type="text"
          :change="changeinput"
          :placeholder="$fanyi('请输入配送单号')"
          v-model="searchkeyword"
          :keydownEnter="search"
        />
      </div>
    </template>
    <!--配送单列表 -->
  </headBackBar>
  <div class="content">
    <!-- 弹出框 -->
    <van-popup v-model:show="showpopup" closeable>
      <div class="dlong">
        <div class="title">
          {{ $fanyi("提示") }}
        </div>
        <p>
          {{
            popuptype == 0
              ? $fanyi("是否删除该临时配送单？")
              : $fanyi("是否确认当前配送单已收到货?")
          }}
        </p>
        <div class="footer">
          <div @click="showpopup = false">{{ $fanyi("取消") }}</div>
          <div @click="confim">{{ $fanyi("确认") }}</div>
        </div>
      </div>
    </van-popup>
    <van-tabs
      v-model:active="active"
      @click-tab="clicktabs"
      title-inactive-color="#000000"
      line-width="30px"
      animated
      title-active-color="#B4272B"
    >
      <!-- 临时保存 -->
      <van-tab :title="$fanyi('临时保存') + '(' + tabnum.save + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Temporaryrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Temporaryfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Temporarylist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
                <div
                  class="baocun"
                  v-if="item.status_name == '临时保存'"
                  @click.stop="temporarydelete(item)"
                >
                  {{ $fanyi("删除") }}
                </div>
              </div>
              <noData v-if="!loading && !deliverdatas.Temporarylist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 全部 -->
      <van-tab :title="$fanyi('全部') + '(' + tabnum.all + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Allrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Allfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Alllist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
                <div
                  class="baocun"
                  v-if="item.status_name == '临时保存'"
                  @click.stop="temporarydelete(item)"
                >
                  {{ $fanyi("删除") }}
                </div>
                <div
                  class="fukuan"
                  v-if="item.status_name == '等待付款'"
                  @click.stop="payment(item)"
                >
                  {{ $fanyi("立即付款") }}
                </div>
                <div
                  class="qianshou"
                  v-if="item.status_name == '已发货'"
                  @click.stop="signoff(item)"
                >
                  {{ $fanyi("签收") }}
                </div>
              </div>
              <noData v-if="!loading && !deliverdatas.Alllist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 装箱中 -->
      <van-tab :title="$fanyi('装箱中') + '(' + tabnum.vanning + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Vanningrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Vanningfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Vanninglist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <noData v-if="!loading && !deliverdatas.Vanninglist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 等待付款 -->
      <van-tab :title="$fanyi('等待付款') + '(' + tabnum.waitpayment + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Pendingrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Pendingfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Pendinglist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
                <div
                  class="fukuan"
                  v-if="item.status_name == '等待付款' && !item.p_porder_sn"
                  @click="payment.stop(item)"
                >
                  {{ $fanyi("立即付款") }}
                </div>
              </div>
              <noData v-if="!loading && !deliverdatas.Pendinglist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 发货中 -->
      <van-tab :title="$fanyi('发货中') + '(' + tabnum.shipping + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Shippingrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Shippingfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Shippinglist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <noData v-if="!loading && !deliverdatas.Shippinglist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 已发货 -->
      <van-tab :title="$fanyi('已发货') + '(' + tabnum.shipped + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Shippedrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Shippedfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Shippedlist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
                <div
                  class="qianshou"
                  v-if="item.status_name == '已发货'"
                  @click.stop="signoff(item)"
                >
                  {{ $fanyi("签收") }}
                </div>
              </div>
              <noData v-if="!loading && !deliverdatas.Shippedlist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 已签收 -->
      <van-tab :title="$fanyi('已签收') + '(' + tabnum.signed + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.Receivedrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.Receivedfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.Receivedlist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_pay_confirm
                      ? item.datetime_pay_confirm
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("发货时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_delivery
                      ? item.datetime_delivery
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("签收时间") }}：</span>
                  <span class="right-text">{{
                    item.datetime_client_receive
                      ? item.datetime_client_receive
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <noData v-if="!loading && !deliverdatas.Receivedlist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 已取消 -->
      <van-tab
        :title="$fanyi('已取消') + '(' + tabnum.receivedSuccessfully + ')'"
      >
        <div class="main">
          <van-pull-refresh
            v-model="deliverdatas.receivedSuccessfullyrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="deliverdatas.receivedSuccessfullyfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
              class="yiquxiao"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage(
                    '/deliveryDetails?pOrder_sn=' + item.porder_sn
                  )
                "
                v-for="item in deliverdatas.receivedSuccessfullylist"
                :key="item.id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.porder_sn);
                      }
                    "
                  >
                    {{ $fanyi("配送单号") }}：{{ item.porder_sn }}
                  </div>
                  <div class="state">{{ $fanyi(item.status_name) }}</div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}；</span>
                  <span class="right-text">{{
                    item.rowNumber ? item.rowNumber : "一一一一一"
                  }}</span>
                </p>
                <!-- <p>
                  <span class="left-title">{{ $fanyi('收件人') }}：</span>
                  <span class="right-text">{{}}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi('收件地址') }}：</span>
                  <span class="right-text"></span>
                </p> -->
                <p>
                  <span class="left-title"
                    >{{ $fanyi("配送单创建时间") }}：</span
                  >
                  <span class="right-text">{{
                    item.created_at ? item.created_at : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("取消时间") }}：</span>
                  <span class="right-text">{{
                    item.cancel_at ? item.cancel_at : "一一一一一"
                  }}</span>
                </p>
              </div>
              <noData
                v-if="!loading && !deliverdatas.receivedSuccessfullylist.length"
              />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
  </div>
  <!-- 内容区域 -->
</template>
<script setup>
import noData from "./components/nodata.vue";
import dayjs from "dayjs";
import inputBoxVue from "@/components/inputBox/index.vue";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
import { useRoute } from "vue-router";
const route = useRoute();

const { proxy } = getCurrentInstance();
const searchkeyword = ref("");
// 临时保存 0 全部 1 装箱中 2 等待付款 3 发货中4 已发货 5  已签收 6  已取消 7
// if(route)
// console.log(route);
const active = ref(0);
// 页面加载进项判断路由  temporary 临时配送单 vanning 装箱中 payment 等待付款 sendout 发货中 shipped 已发货

if (route.query.type == "temporary") {
  active.value = 0;
}
if (route.query.type == "vanning") {
  active.value = 2;
}
if (route.query.type == "payment") {
  active.value = 3;
}
if (route.query.type == "sendout") {
  active.value = 4;
}
if (route.query.type == "shipped") {
  active.value = 5;
}
if (route.query.type == "delivered") {
  active.value = 6;
}
if (route.query.type == "receivedSuccessfully") {
  active.value = 7;
}
const showpopup = ref(false);
const popuptype = ref(null); // 弹窗类型 0 临时保存可以删除， 已发货 5 可以签收
const pageSize = ref(20); // 每页条数
const state = ref("临时保存"); // 订单状态
const tabnum = ref({
  save: 0, //临时保存
  all: 0, //全部
  vanning: 0, //装箱中
  waitpayment: 0, //等待付款
  signed: 0, //已签收
  shipped: 0, //已发货
  shipping: 0, //发货中
  receivedSuccessfully: 0, //已取消
});
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});

// 定义加载数据所有状态公用，所以要进项判断
const loading = ref(false);
// const finished = ref(false);
const refreshing = ref(false);
// 定义是所有配送单数据列表和page
const deliverdatas = ref({
  // 临时保存
  Temporarylist: [], // 临时保存数据列表
  Temporarypage: 0, //临时保存数据page
  Temporaryfinished: false, //临时保存的加载完成
  Temporaryrefreshing: false, //临时保存下拉触发
  //全部
  Alllist: [], //全部配送单列表
  Allpage: 0, //全部的配送单页码
  Allfinished: false, //所有的订单数据加载完成
  Allrefreshing: false, //全部订单的下拉触发
  //装箱中
  Vanninglist: [], //装箱中的数据列表
  Vanningpage: 0, //装箱中的数据页码
  Vanningfinished: false, //装箱中的数据加载完成
  Vanningrefreshing: false, //装箱中的数据下拉触发
  // 等待付款
  Pendinglist: [], //等待付款的数据列表
  Pendingpage: 0, // 等待付款的数据页码
  Pendingfinished: false, // 等待付款数据加载完成
  Pendingrefreshing: false, //等待付款数据下拉触发
  // 发货中
  Shippinglist: [], //发货中的数据列表
  Shippingpage: 0, //发货中的页码
  Shippingfinished: false, // 发货中加载完成
  Shippingrefreshing: false, // 发货中的下拉刷新
  // 已发货
  Shippedlist: [], //已发货的数据列表
  Shippedpage: 0, // 已发货的页码
  Shippedfinished: false, // 已发货的数据加载完成
  Shippedrefreshing: false, // 已发货的下拉触发
  // 已签收
  Receivedlist: [], //已签收的数据列表
  Receivedpage: 0, // 已签收的数据页码
  Receivedfinished: false, //已签收的数据加载完成
  Receivedrefreshing: false, // 已签收下拉触发
  // 已取消
  receivedSuccessfullylist: [], //已取消的数据列表
  receivedSuccessfullypage: 0, // 已取消的数据页码
  receivedSuccessfullyfinished: false, //已取消的数据加载完成
  receivedSuccessfullyrefreshing: false, // 已取消下拉触发
});
// 获取顶部tab数量
const storageDeliverNum = async () => {
  const res = await proxy.$api.storageDeliverNum();
  if (res.code !== 0) {
    Toast.clear();
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  res.data.forEach((item) => {
    if (item.name == "临时保存") {
      tabnum.value.save = item.count;
    } else if (item.name == "全部") {
      tabnum.value.all = item.count;
    } else if (item.name == "装箱中") {
      tabnum.value.vanning = item.count;
    } else if (item.name == "等待付款") {
      tabnum.value.waitpayment = item.count;
    } else if (item.name == "已签收") {
      tabnum.value.signed = item.count;
    } else if (item.name == "已发货") {
      tabnum.value.shipped = item.count;
    } else if (item.name == "发货中") {
      tabnum.value.shipping = item.count;
    } else if (item.name == "已取消") {
      tabnum.value.receivedSuccessfully = item.count;
    }
  });
};
storageDeliverNum();
// 页面加载loading
const onLoad = async () => {
  // 临时保存 0 全部 1 装箱中 2 等待付款 3 发货中4 已发货 5  已签收 6
  // 首先进行判断是哪个订单状态
  let page = 0;
  if (active.value == 0) {
    state.value = "临时保存";
    if (deliverdatas.value.Temporarylist.length == 0) {
      deliverdatas.value.Temporarypage = 1;
    } else {
      deliverdatas.value.Temporarypage += 1;
    }
    page = deliverdatas.value.Temporarypage;
    if (deliverdatas.value.Temporaryfinished == true) {
      deliverdatas.value.Temporaryrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
  } else if (active.value == 1) {
    state.value = "全部";

    if (deliverdatas.value.Alllist.length == 0) {
      deliverdatas.value.Allpage = 1;
    } else {
      deliverdatas.value.Allpage += 1;
    }

    page = deliverdatas.value.Allpage;
    if (deliverdatas.value.Allfinished == true) {
      deliverdatas.value.Allrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
  } else if (active.value == 2) {
    state.value = "装箱中";
    if (deliverdatas.value.Vanningfinished == true) {
      deliverdatas.value.Vanningrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (deliverdatas.value.Vanninglist.length == 0) {
      deliverdatas.value.Vanningpage = 1;
    } else {
      deliverdatas.value.Vanningpage += 1;
    }

    page = deliverdatas.value.Vanningpage;
  } else if (active.value == 3) {
    state.value = "等待付款";
    if (deliverdatas.value.Pendingfinished == true) {
      deliverdatas.value.Pendingrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (deliverdatas.value.Pendinglist.length == 0) {
      deliverdatas.value.Pendingpage = 1;
    } else {
      deliverdatas.value.Pendingpage += 1;
    }

    page = deliverdatas.value.Pendingpage;
  } else if (active.value == 4) {
    state.value = "发货中";
    if (deliverdatas.value.Shippingfinished == true) {
      deliverdatas.value.Shippingrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (deliverdatas.value.Shippinglist.length == 0) {
      deliverdatas.value.Shippingpage = 1;
    } else {
      deliverdatas.value.Shippingpage += 1;
    }

    page = deliverdatas.value.Shippingpage;
  } else if (active.value == 5) {
    state.value = "已发货";
    if (deliverdatas.value.Shippedfinished == true) {
      deliverdatas.value.Shippedrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }

    if (deliverdatas.value.Shippedlist.length == 0) {
      deliverdatas.value.Shippedpage = 1;
    } else {
      deliverdatas.value.Shippedpage += 1;
    }

    page = deliverdatas.value.Shippedpage;
  } else if (active.value == 6) {
    state.value = "已签收";
    if (deliverdatas.value.Receivedfinished == true) {
      deliverdatas.value.Receivedrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (deliverdatas.value.Receivedlist.length == 0) {
      deliverdatas.value.Receivedpage = 1;
    } else {
      deliverdatas.value.Receivedpage += 1;
    }

    page = deliverdatas.value.Receivedpage;
  } else if (active.value == 7) {
    state.value = "已取消";
    if (deliverdatas.value.receivedSuccessfullyfinished == true) {
      deliverdatas.value.receivedSuccessfullyrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (deliverdatas.value.receivedSuccessfullylist.length == 0) {
      deliverdatas.value.receivedSuccessfullypage = 1;
    } else {
      deliverdatas.value.receivedSuccessfullypage += 1;
    }

    page = deliverdatas.value.receivedSuccessfullypage;
  }
  let local_active = active.value;
  // 获取配送单列表
  const res = await proxy.$api.storageDeliverList({
    status_name: state.value,
    page: page,
    pageSize: pageSize.value,
  });
  // 请求失败判断关闭加载效果
  if (res.code !== 0) {
    Toast.clear();
    loading.value = false;
    if (active.value == 0) {
      deliverdatas.value.Temporaryrefreshing = false;
      deliverdatas.value.Temporaryfinished = true;
    } else if (active.value == 1) {
      deliverdatas.value.Allrefreshing = false;
      deliverdatas.value.Allfinished = true;
    } else if (active.value == 2) {
      deliverdatas.value.Vanningrefreshing = false;
      deliverdatas.value.Vanningfinished = true;
    } else if (active.value == 3) {
      deliverdatas.value.Pendingrefreshing = false;
      deliverdatas.value.Pendingfinished = true;
    } else if (active.value == 4) {
      deliverdatas.value.Shippingrefreshing = false;
      deliverdatas.value.Shippingfinished = true;
    } else if (active.value == 5) {
      // 判断是下刷新海是触底  已发货
      deliverdatas.value.Shippedrefreshing = false;
      deliverdatas.value.Shippedfinished = true;
    } else if (active.value == 6) {
      deliverdatas.value.Receivedrefreshing = false;
      deliverdatas.value.Receivedfinished = true;
    }
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  // 请求成功后再次进行判断，渲染那个状态数据
  // 关闭loading
  Toast.clear();

  if (active.value == 0) {
    // 判断是下刷新海是触底  临时保存
    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.Temporarypage = 0;

      return;
    }

    if (deliverdatas.value.Temporaryrefreshing == true) {
      deliverdatas.value.Temporarylist = [
        ...res.data?.data,
        ...deliverdatas.value.Temporarylist,
      ];
    } else {
      deliverdatas.value.Temporarylist = [
        ...deliverdatas.value.Temporarylist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Temporaryrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Temporarylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Temporaryfinished = true;
    }
  } else if (active.value == 1) {
    // 判断是下刷新海是触底   全部

    if (deliverdatas.value.Allrefreshing == true) {
      deliverdatas.value.Alllist = [
        ...res.data?.data,
        ...deliverdatas.value.Alllist,
      ];
    } else {
      deliverdatas.value.Alllist = [
        ...deliverdatas.value.Alllist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Allrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Alllist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Allfinished = true;
    }
  } else if (active.value == 2) {
    // 判断是下刷新海是触底  装箱中

    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.Vanningpage = 0;

      return;
    }

    if (deliverdatas.value.Vanningrefreshing == true) {
      deliverdatas.value.Vanninglist = [
        ...res.data?.data,
        ...deliverdatas.value.Vanninglist,
      ];
    } else {
      deliverdatas.value.Vanninglist = [
        ...deliverdatas.value.Vanninglist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Vanningrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Vanninglist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Vanningfinished = true;
    }
  } else if (active.value == 3) {
    // 判断是下刷新海是触底  等待付款
    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.Pendingpage = 0;

      return;
    }
    console.log(res);
    if (deliverdatas.value.Pendingrefreshing == true) {
      deliverdatas.value.Pendinglist = [
        ...res.data?.data,
        ...deliverdatas.value.Pendinglist,
      ];
    } else {
      deliverdatas.value.Pendinglist = [
        ...deliverdatas.value.Pendinglist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Pendingrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Pendinglist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Pendingfinished = true;
    }
  } else if (active.value == 4) {
    // 判断是下刷新海是触底  发货中
    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.Shippingpage = 0;

      return;
    }
    console.log(res);
    if (deliverdatas.value.Shippingrefreshing == true) {
      deliverdatas.value.Shippinglist = [
        ...res.data?.data,
        ...deliverdatas.value.Shippinglist,
      ];
    } else {
      deliverdatas.value.Shippinglist = [
        ...deliverdatas.value.Shippinglist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Shippingrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Shippinglist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Shippingfinished = true;
    }
  } else if (active.value == 5) {
    // 判断是下刷新海是触底  已发货

    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.Shippedpage = 0;
      return;
    }
    if (deliverdatas.value.Shippedrefreshing == true) {
      deliverdatas.value.Shippedlist = [
        ...res.data?.data,
        ...deliverdatas.value.Shippedlist,
      ];
    } else {
      deliverdatas.value.Shippedlist = [
        ...deliverdatas.value.Shippedlist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Shippedrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Shippedlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Shippedfinished = true;
    }
  } else if (active.value == 6) {
    // 判断是下刷新海是触底  已签收
    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.Receivedpage = 0;

      return;
    }
    console.log(res);
    if (deliverdatas.value.Receivedrefreshing == true) {
      deliverdatas.value.Receivedlist = [
        ...res.data?.data,
        ...deliverdatas.value.Receivedlist,
      ];
    } else {
      deliverdatas.value.Receivedlist = [
        ...deliverdatas.value.Receivedlist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.Receivedrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.Receivedlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.Receivedfinished = true;
    }
  } else if (active.value == 7) {
    // 判断是下刷新海是触底  已取消
    if (res.data.data.length > 0 && local_active != active.value) {
      deliverdatas.value.receivedSuccessfullypage = 0;

      return;
    }
    if (deliverdatas.value.receivedSuccessfullyrefreshing == true) {
      deliverdatas.value.receivedSuccessfullylist = [
        ...res.data?.data,
        ...deliverdatas.value.receivedSuccessfullylist,
      ];
    } else {
      deliverdatas.value.receivedSuccessfullylist = [
        ...deliverdatas.value.receivedSuccessfullylist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    deliverdatas.value.receivedSuccessfullyrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      deliverdatas.value.receivedSuccessfullylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      deliverdatas.value.receivedSuccessfullyfinished = true;
    }
  }
};
// 点击tabs 触发事件
const clicktabs = (val) => {
  active.value = val.name;
  // 进项判断有没有进行搜索
  if (orderstate.value == "临时保存") {
    active.value = 0;
    deliverdatas.value.Temporarypage = 0;
    deliverdatas.value.Temporaryfinished = false;
  } else if (orderstate.value == "装箱中") {
    active.value = 2;
    deliverdatas.value.Vanningpage = 0;
    deliverdatas.value.Vanningfinished = false;
  } else if (orderstate.value == "等待付款") {
    active.value = 3;
    deliverdatas.value.Pendingpage = 0;
    deliverdatas.value.Pendingfinished = false;
  } else if (orderstate.value == "发货中") {
    active.value = 4;
    deliverdatas.value.Shippingpage = 0;
    deliverdatas.value.Shippingfinished = false;
  } else if (orderstate.value == "已发货") {
    active.value = 5;
    deliverdatas.value.Shippedpage = 0;
    deliverdatas.value.Shippedfinished = false;
  } else if (orderstate.value == "已签收") {
    active.value = 6;
    deliverdatas.value.Receivedpage = 0;
    deliverdatas.value.Receivedfinished = false;
  } else if (orderstate.value == "已评价") {
    active.value = 6;
    deliverdatas.value.Receivedpage = 0;
    deliverdatas.value.Receivedfinished = false;
  } else if (orderstate.value == "已取消") {
    active.value = 7;
    deliverdatas.value.receivedSuccessfullypage = 0;
    deliverdatas.value.receivedSuccessfullyfinished = false;
  }
};
// 页面下拉触发
const onRefresh = () => {
  onLoad();
};
// 搜索的订单状态
const orderstate = ref(null);
// 搜索订单
const search = async () => {
  //  点击后开始发请求搜索
  if (searchkeyword.value !== "") {
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    const res = await proxy.$api.storageDeliverList({
      status_name: "全部",
      keywords: searchkeyword.value,
    });
    console.log(res);
    if (res.code !== 0) {
      Toast.fail(proxy.$fanyi(res.msg));
      Toast.clear();
      return;
    }

    if (res.data.data.length == 0) {
      Toast(proxy.$fanyi("查询不到您输入的配送单号"));
      Toast.clear();
      return;
    }
    orderstate.value = res.data.data[0].status_name;
    // console.log(state);
    Toast.clear();
    // 判断是那种状态跳转到对应的页面
    if (orderstate.value == "临时保存") {
      active.value = 0;
      deliverdatas.value.Temporarylist = res.data.data;
      deliverdatas.value.Temporaryfinished = true;
    } else if (orderstate.value == "装箱中") {
      active.value = 2;
      deliverdatas.value.Vanninglist = res.data.data;
      deliverdatas.value.Vanningfinished = true;
    } else if (
      orderstate.value == "等待付款" ||
      orderstate.value == "待财务确认"
    ) {
      active.value = 3;
      deliverdatas.value.Pendinglist = res.data.data;
      deliverdatas.value.Pendingfinished = true;
    } else if (orderstate.value == "发货中") {
      active.value = 4;
      deliverdatas.value.Shippinglist = res.data.data;
      deliverdatas.value.Shippingfinished = true;
    } else if (orderstate.value == "已发货") {
      active.value = 5;
      deliverdatas.value.Shippedlist = res.data.data;
      deliverdatas.value.Shippedfinished = true;
    } else if (orderstate.value == "已签收") {
      active.value = 6;
      deliverdatas.value.Receivedlist = res.data.data;
      deliverdatas.value.Receivedfinished = true;
    } else if (orderstate.value == "已评价") {
      active.value = 6;
      deliverdatas.value.Receivedlist = res.data.data;
      deliverdatas.value.Receivedfinished = true;
    } else if (orderstate.value == "已取消") {
      active.value = 7;
      deliverdatas.value.receivedSuccessfullylist = res.data.data;
      deliverdatas.value.receivedSuccessfullyfinished = true;
    }
  } else {
    Toast(proxy.$fanyi("请输入配送单号"));
  }
};
// 输入框变化
const changeinput = () => {
  // 判断，如果输入框为空，就加载当前状态数据
};
// 要删除或者签收的那条数据
const orderitem = ref(null);
// 临时保存订单删除
const temporarydelete = async (item) => {
  orderitem.value = item;
  showpopup.value = true;
  popuptype.value = 0;
};
// 等待付款付款按钮
const payment = (item) => {
  proxy.$fun.routerToPage("/deliveryDetails?pOrder_sn=" + item.porder_sn);
};
// 签收按钮
const signoff = (item) => {
  orderitem.value = item;
  showpopup.value = true;
  popuptype.value = 5;
};
// 点击确认
const confim = async () => {
  // 进项判断是删除0，还是签收5 popuptype
  // console.log(popuptype.value);
  console.log(orderitem.value);
  if (popuptype.value == 0) {
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    const res = await proxy.$api.storageTampDeliverDelete({
      porder_sn: orderitem.value.porder_sn,
    });
    if (res.code != 0) {
      Toast.fail(proxy.$fanyi(res.msg));
      Toast.clear();
      return;
    }
    Toast.clear();
    // 临时保存状态的删除
    if (active.value == 0) {
      deliverdatas.value.Temporarypage = 0;
      deliverdatas.value.Temporaryfinished = false;
      deliverdatas.value.Temporarylist = [];
    }
    if (active.value == 1) {
      deliverdatas.value.Allpage = 0;
      deliverdatas.value.Allfinished = false;
      deliverdatas.value.Alllist = [];
    }
    showpopup.value = false;
    Toast.success(proxy.$fanyi("删除成功"));
    storageDeliverNum();
  }
  if (popuptype.value == 5) {
    //  说明是签收
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    const res = await proxy.$api.internationalLogisticsClientReceive({
      porder_sn: orderitem.value.porder_sn,
    });
    if (res.code != 0) {
      Toast.fail(proxy.$fanyi(res.msg));
      Toast.clear();
      return;
    }
    Toast.clear();

    // 发货中的签收的删除
    if (active.value == 5) {
      deliverdatas.value.Shippedpage = 0;
      deliverdatas.value.Shippedfinished = false;
      deliverdatas.value.Shippedlist = [];
    }
    if (active.value == 1) {
      deliverdatas.value.Allpage = 0;
      deliverdatas.value.Allfinished = false;
      deliverdatas.value.Alllist = [];
    }
    showpopup.value = false;
    Toast.success("签收成功");
    storageDeliverNum();
  }
};

// 复制单号
const copySn = (order_sn) => {
  navigator.clipboard.writeText(order_sn);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

:deep().van-overlay {
  opacity: 0.4;
}

// 弹出框
.dlong {
  width: 500px;
  height: 276px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px 50px;

  .title {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 47px;
  }

  p {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 28px;

    font-weight: 400;
    justify-content: center;
    color: #999999;
    margin-bottom: 45px;
  }

  .footer {
    display: flex;
    padding: 0 25px;
    justify-content: space-between;

    div {
      &:first-child {
        width: 160px;
        height: 60px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        font-size: 28px;

        font-weight: 400;
        color: #000000;
        align-items: center;
      }

      &:last-child {
        width: 160px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
        background: #b4272b;
        border-radius: 6px;
      }
    }
  }
}

:deep() .van-icon-cross {
  width: 19px;
  height: 18px;
  top: 20px;
  right: 28px;
  font-size: 28px;
}

.search {
  width: 570px;
}

.content {
  width: 100%;
  min-height: 1380px;
  background-color: #f6f6f6;

  :deep() .van-tab__text {
    font-size: 28px;

    font-weight: 500;
  }

  :deep() .van-tabs__line {
    background: #b4272b;
  }

  :deep().van-tabs__wrap {
    position: sticky;
    top: 126px;
    z-index: 999;
    height: 68px;
  }

  .main {
    width: 100%;
    min-height: 1380px;
    background-color: #f6f6f6;
    padding: 30px;
    padding-top: 20px;

    .item {
      position: relative;
      width: 690px;

      background: #ffffff;
      border-radius: 6px;
      padding-top: 37px;
      padding-left: 28px;
      padding-right: 28px;
      padding-bottom: 15px;
      margin-bottom: 20px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;

        .Singlenumbe {
          width: 330px;
          font-size: 28px;
          line-height: 35px;
          font-weight: 400;
          color: #000000;
        }

        .state {
          font-size: 24px;

          font-weight: 400;
          color: #b4272b;
        }
      }

      p {
        margin-bottom: 10px;
        height: 36px;
        display: flex;
        align-items: center;

        .left-title {
          font-size: 24px;

          font-weight: 400;
          color: #999999;
        }

        .right-text {
          font-size: 24px;

          font-weight: 400;
          color: #000000;
        }
      }

      .baocun {
        position: absolute;
        right: 30px;
        width: 160px;
        height: 60px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        bottom: 30px;
        font-size: 28px;

        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .fukuan {
        position: absolute;
        right: 30px;
        width: 160px;
        height: 60px;
        background: #b4272b;
        border-radius: 6px;
        bottom: 30px;
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .qianshou {
        position: absolute;
        right: 30px;
        width: 160px;
        height: 60px;
        background: #27b470;
        border-radius: 6px;
        bottom: 30px;
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .yiquxiao {
    .item {
      min-height: unset;
      padding-bottom: 10px;
    }
  }
}
</style>
